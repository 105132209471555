<template>
  <v-container fluid>
    <!--BOC:[breadcrumb]-->
    <ABreadcrumb :items="breadcrumb.items" />
    <!--EOC-->
    <div ref="table"></div>
  </v-container>
</template>

<script>
//BOC:[breadcrumb]
import Breadcrumb from "@/objects/breadcrumb";
//EOC
//BOC:[tabulator]
import { TabulatorFull as Tabulator } from "tabulator-tables"; //import Tabulator library
//EOC
//BOC:[faker]
import { faker } from "@faker-js/faker";
//EOC
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    //
  }),
  props: [
    //
  ],
  data: () => ({
    //BOC:[breadcrumb]
    breadcrumb: new Breadcrumb(),
    //EOC
  }),
  created() {
    //BOC:[breadcrumb]
    this.breadcrumb.add({
      text: "Dashboard",
      to: { name: "PageConsoleDashboard" },
      exact: true,
    });
    this.breadcrumb.add({
      text: "Services",
      to: { name: "PageServiceBrowse" },
      exact: true,
    });
    this.breadcrumb.add({
      text: "Leads",
      to: { name: "PageServiceDashboard", params: { serviceKey: "lead" } },
      exact: true,
    });
    this.breadcrumb.add({
      text: "Contacts",
      to: { name: "MockupLeadContactBrowse" },
      exact: true,
    });
    //EOC
  },
  mounted() {
    var data = [];
    for (let i = 1; i <= 100; i++) {
      data.push({
        id: i,
        companyName: faker.company.name(),
        name: faker.person.fullName(),
        position: faker.person.jobTitle(),
        email: faker.internet.email(),
        phone: faker.phone.number(),
        salesperson: faker.person.fullName(),
        date: faker.date.anytime(),
      });
    }
    this.tabulator = new Tabulator(this.$refs.table, {
      layout: "fitColumns",
      pagination: true,
      paginationSize: 5,
      data: data, //set initial table data
      columns: [
        { title: "Company", field: "companyName" },
        { title: "Name", field: "name" },
        { title: "Position", field: "position" },
        { title: "Email", field: "email" },
        { title: "Phone", field: "phone" },
        { title: "Last contacted by", field: "salesperson" },
        { title: "Last contact date", field: "date" },
        { title: "Deals", field: "deals" },
      ],
    });
  },
  methods: {
    //
  },
};
</script>